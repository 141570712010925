'use client';

/* eslint-disable react/no-unescaped-entities */

import { HStack, Heading, Stack, Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';

export default function Welcome() {
  const t = useTranslations();
  return (
    <>
      {/* <FirstTimeMarketplace isOpen={isOpen} onClose={onClose} /> */}
      <Stack gap="40px">
        <Stack gap="32px">
          <HStack justify="space-between" align="center">
            <Stack spacing="0" px={['2px', '4px', '8px']}>
              <Heading>{t('trade-smarter-with-intents')}</Heading>
              <Text fontSize="m" color="gray.400">
                {t(
                  'maximize-profits-and-minimize-losses-with-automated-strategies-for-on-chain-transactions'
                )}
              </Text>
            </Stack>
          </HStack>
        </Stack>
      </Stack>
    </>
  );
}

'use client';

/* eslint-disable react/prop-types */

import type { StackProps } from '@chakra-ui/react';
import { HStack, Link, Tooltip } from '@chakra-ui/react';
import NextLink from 'next/link';
import { usePathname } from 'next/navigation';
import type { FC } from 'react';
import React from 'react';

interface LinkItemP {
  name: string;
  path: string;
  params?: string;
  label?: string;
}

// const activeStyle = { color: 'white', bg: '#181B1F' };
const activeStyle = {
  color: 'gray.200',
  bg: 'white',
  _dark: { color: 'white', bg: '#181B1F' },
};

const LinkItem = React.memo(({ name, path, params, label }: LinkItemP) => {
  const pathname = usePathname();
  const isActive = pathname === path;
  const isNotAllowed = path === '#';
  return (
    <Tooltip label={label} aria-label={name}>
      <Link
        data-cy="switch-item"
        as={NextLink}
        href={params ? `${path}${params}` : path}
        px="20px"
        py="4px"
        rounded="13px"
        fontWeight="600"
        cursor={isNotAllowed ? 'not-allowed' : 'pointer'}
        _hover={isNotAllowed ? undefined : activeStyle}
        style={{ textDecoration: 'none' }}
        // _activeLink={activeStyle}
        sx={isActive ? activeStyle : undefined}
        prefetch
        scroll
        color={isNotAllowed ? 'gray.500' : 'initial'}
      >
        {name}
      </Link>
    </Tooltip>
  );
});

interface CustomSwitchProps extends StackProps {
  items: {
    name: string;
    path: string;
    params?: string;
    exact?: boolean;
    label?: string;
  }[];
}

export const CustomSwitch: FC<CustomSwitchProps> = React.memo(
  ({ items, ...props }) => {
    return (
      <HStack
        p="7px"
        spacing="7px"
        bg="#D8D8D8"
        _dark={{ bg: 'gray.800', color: 'white' }}
        rounded="18px"
        {...props}
      >
        {items.map((item) => (
          <LinkItem
            key={item.name}
            name={item.name}
            path={item.path}
            label={item.label}
            params={item.params}
          />
        ))}
      </HStack>
    );
  }
);

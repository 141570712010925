import { Text } from '@chakra-ui/react';
import { type useTranslations } from 'next-intl';
import { type ReactNode } from 'react';

const DescParagraph = ({ children }: { children: ReactNode }) => {
  return (
    <Text as="span" fontWeight="semibold" color="gray.800" pr={1}>
      {children}
    </Text>
  );
};

const ListItem = ({ children }: { children: ReactNode }) => {
  return (
    <Text>
      <li>{children}</li>
    </Text>
  );
};

export const getFilterSuspiciousTokensDesc = ({
  t,
}: {
  t: ReturnType<typeof useTranslations>;
}) => {
  return (
    <Text>
      <Text>{t('enabling-this-filter')}:</Text>
      <ListItem>
        <DescParagraph>{t('low-liquidity')}:</DescParagraph>
        {t('tokens-with-insufficient-market-liquidity')}.
      </ListItem>
      <ListItem>
        <DescParagraph>{t('potential-scams')}:</DescParagraph>
        {t('tokens-identified-as-scams-or-fraudulent-projects')}.
      </ListItem>
      <ListItem>
        <DescParagraph>{t('honey-pots')}:</DescParagraph>
        {t('tokens-that-prevent-sellers-from-selling-after-purchase')}.
      </ListItem>
      <ListItem>
        <DescParagraph>{t('similar-scam-contracts')}:</DescParagraph>
        {t('tokens-with-contracts-similar-to-known-scams')}.
      </ListItem>
      <ListItem>
        <DescParagraph>{t('closed-source-or-unique-contracts')}:</DescParagraph>
        {t('tokens-with-closed-source-contracts')}.
      </ListItem>
      <Text>
        <DescParagraph>{t('disclaimer')}:</DescParagraph>
        {t('the-list-is-provided-without-warranty')}
      </Text>
    </Text>
  );
};

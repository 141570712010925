'use client';

import {
  Box,
  HStack,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { X } from 'react-feather';

interface IGuardedTokensAlert {
  header: string;
  body: ReactNode;
  footer?: ReactNode;
  canClose?: boolean;
}

export const GuardedTokensAlert: FC<IGuardedTokensAlert> = ({
  header,
  body,
  footer,
  canClose = true,
}) => {
  const t = useTranslations();
  const [closed, setClosed] = useState(false);

  if (canClose && closed) {
    return null;
  }

  return (
    <Stack
      p="55px"
      spacing="0"
      rounded="32px"
      bg="#F9FAFB"
      _dark={{ bg: '#0F151A' }}
    >
      {canClose && (
        <Tooltip label={t('close')}>
          <IconButton
            onClick={() => setClosed(true)}
            icon={<Icon as={X} />}
            size="sm"
            mt="-30px"
            mr="-30px"
            w="fit-content"
            alignSelf="flex-end"
            aria-label="close"
          />
        </Tooltip>
      )}
      <HStack>
        <Stack w="400px" spacing="0" gap="32px">
          <Stack spacing="0" gap="16px">
            <Text h="42px" fontWeight="700" fontSize="28px">
              {header}
            </Text>
            <Stack h="150px" justifyContent="center">
              {body}
            </Stack>
            <Stack h="48px">{footer}</Stack>
          </Stack>
        </Stack>

        <Box visibility="visible" w="180.05px" h="300px">
          <Image w="180.05px" h="300px" src="/images/guarded_tokens.png" />
        </Box>
      </HStack>
    </Stack>
  );
};

import { AnimatePresence, motion } from 'framer-motion';
import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import './TextsUpAnimation.css';

interface ITextsUpAnimation {
  texts: string[];
}

export const TextsUpAnimation: FC<ITextsUpAnimation> = ({ texts }) => {
  const [index, setIndex] = useState(0);
  const [isChecked, setIsChecked] = useState(false);

  const handleNext = useCallback(() => {
    if (index === texts.length - 1) return;
    setIndex((prevIndex) => prevIndex + 1);
    setIsChecked(false);
  }, [index, texts.length]);

  useEffect(() => {
    const timer = setInterval(handleNext, 1500);
    return () => clearInterval(timer);
  }, [handleNext]);

  useEffect(() => {
    const timer = setTimeout(() => setIsChecked(true), 500);
    return () => clearTimeout(timer);
  }, [index]);

  return (
    <div className="TextsUpAnimation">
      <AnimatePresence mode="wait" initial>
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          // className="item"
          transition={{ duration: 0.5, ease: 'linear' }}
        >
          <input type="checkbox" checked={isChecked} readOnly />
          <span style={{ marginLeft: '5px' }}>{texts[index]}</span>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

'use client';

/* eslint-disable complexity */
/* eslint-disable sonarjs/cognitive-complexity */

import { useSession, useVault, useWallet } from '@kiroboio/fct-sdk';
import { useAccount, useNetwork } from 'wagmi';
import { isBuilderDisabled, isMarketplaceOff } from '~/lib/helpers/flags';
import { useUser } from '~/lib/hooks/useUser/useUser';

interface ICustomGuardProps {
  children: React.ReactNode;
  unsupportedNetworkJsx?: React.ReactNode;
  serviceStateMessageJsx?: React.ReactNode;
  connectWalletMessageJsx?: React.ReactNode;
  signMessageJsx?: React.ReactNode;
  signingMessageJsx?: React.ReactNode;
  systemLoadingJsx?: React.ReactNode;
  notWhitelistedJsx?: React.ReactNode;
  missingVaultJsx?: React.ReactNode;
  notOwnerJsx?: React.ReactNode;
  notBuilderJsx?: React.ReactNode;
}

export const GuardWithMessage = ({
  children,
  unsupportedNetworkJsx,
  serviceStateMessageJsx,
  connectWalletMessageJsx,
  // signMessageJsx,
  signingMessageJsx,
  systemLoadingJsx,
  notWhitelistedJsx,
  missingVaultJsx,
  notOwnerJsx,
  notBuilderJsx,
}: ICustomGuardProps) => {
  const {
    // status,
    connectionState,
    // isServiceReady,
    account: { type: accountType },
  } = useSession();
  const { isConnected } = useAccount();
  const { isSigningIn, isSignedOut, isSigningIdle } = useUser();
  const wallet = useWallet();
  const vault = useVault();
  const network = useNetwork();
  const isUnsupportedNetwork = network?.chain?.unsupported;

  const isServiceDisconnected = connectionState === 'disconnected';
  const isBuilder = accountType !== 'user';
  const isServiceReady = connectionState === 'authorized';

  // console.log(new Date().getTime(), 'qweqwe guard', status, isServiceReady);

  // const isWhitelisted = true;
  // const hasVault = true;
  // const isOwner = true;
  const { isRegistered: isWhitelisted } = wallet?.data?.raw || {
    isRegistered: false,
  };
  const { hasVault, isOwner } = vault?.data?.raw || {
    hasVault: false,
    isOwner: false,
  };

  // console.log('qweasdzxc', status, connectionState, isConnected);

  if (unsupportedNetworkJsx && isUnsupportedNetwork) {
    return unsupportedNetworkJsx;
  }
  if (serviceStateMessageJsx && isServiceDisconnected) {
    return serviceStateMessageJsx;
  }
  if (systemLoadingJsx && !isServiceReady) {
    return systemLoadingJsx;
  }
  if (notBuilderJsx && !isBuilder && !isBuilderDisabled && isMarketplaceOff) {
    return notBuilderJsx;
  }
  if (
    connectWalletMessageJsx &&
    (!isConnected || isSigningIdle || isSignedOut)
  ) {
    return connectWalletMessageJsx;
  }
  // if (signMessageJsx && isSignedOut) {
  //   return signMessageJsx;
  // }

  if (signingMessageJsx && isSigningIn) {
    // || isSilentSigningIn)) {
    return signingMessageJsx;
  }
  if (notWhitelistedJsx && !isWhitelisted) {
    return notWhitelistedJsx;
  }
  if (missingVaultJsx && !hasVault) {
    return missingVaultJsx;
  }
  if (notOwnerJsx && hasVault && !isOwner) {
    return notOwnerJsx;
  }
  return children;
};

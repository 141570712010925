/* eslint-disable react/no-unused-prop-types */
import {
  Box,
  Collapse,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Text,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type FC,
  type SetStateAction,
} from 'react';

import type {
  AppsDefiDataItemType,
  AppsDefiDataOrderByType,
  OrderDirType,
  useAppsDefiDataList,
} from '@kiroboio/fct-sdk';

import { InfoCircle } from 'iconsax-react';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { Search, X } from 'react-feather';
import PulseLoader from 'react-spinners/PulseLoader';
import { usePrevious, useSessionStorage } from 'react-use';
import { useBackgroundHexColor, useTextHexColor } from '~/lib/hooks/colors';
import {
  defiAppsId,
  useDefiAppsModalActions,
  useDefiAppsStateModal,
} from '~/lib/hooks/useModals/useComplexModals';
import type { DivProps } from '~/lib/styles/wrappers/StyledComponentTypes';
import { CopyButton } from '../../general/CopyButton';
import { CustomSwitch } from '../../general/CustomSwitch';
import { InfiniteScrollEfficient } from '../../general/InfiniteScrollEfficient';
import { RouteTypes } from '../routeTypes';
import {
  generateTradingTokenComponents,
  isWithSecurityPassedSwitch,
  MIN_TABLE_WIDTH,
  TOOL_WIDTH,
} from './config';
import { getFilterSuspiciousTokensDesc } from './getFilterSuspiciousTokensDesc';
// @ts-expect-error: noy typed
import { useIsVisible } from 'react-is-visible';
import { useAccount } from 'wagmi';
import { useUser } from '~/lib/hooks/useUser/useUser';
import { ConnectWalletAlertMessage } from '../../general/AlertMessages';
import { FirstGuardedTokensAlert } from './FirstGuardedTokensAlert';
import { GuardedAndEmptyTokensAlert } from './GuardedAndEmptyTokensAlert';

type Apps = ReturnType<typeof useAppsDefiDataList>['list'];
interface ITradingTableList {
  apps: Apps;
  type: RouteTypes;
  orderDir: OrderDirType;
  orderBy: AppsDefiDataOrderByType;
  onRouteChange: (route: RouteTypes) => void;
  onOrderChange: (nextOrderBy: AppsDefiDataOrderByType) => void;
  isSecurityPassed?: boolean;
  setIsSecurityPassed: Dispatch<SetStateAction<boolean | undefined>>;
  fetchNext?: () => void;
  hasMore?: boolean;
  isLoading?: boolean;
}

const ALWAYS_SHOW_APPS_AMOUNT = 50;
const ROW_PADDING_LEFT = '8px';
const TitleWrapper = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & DivProps) => {
  return (
    <Box width="full">
      <Box
        textAlign="left"
        cursor="pointer"
        w="inherit"
        {...props}
        py="6px"
        px="6px"
        textTransform="capitalize"
      >
        {children}
      </Box>
    </Box>
  );
};

const CellWrapper = ({
  children,
  isLoading,
  ...props
}: {
  children: React.ReactNode;
  isLoading?: boolean;
} & DivProps) => {
  return (
    <Stack
      zIndex="1"
      textAlign="left"
      cursor="pointer"
      transition="filter 0.4s ease-in-out, opacity 0.4s ease-in-out"
      opacity={1}
      filter="blur(0px)"
      sx={isLoading ? { filter: 'blur(3px)', opacity: 0.1 } : {}}
      {...props}
    >
      {children}
    </Stack>
  );
};

const TableRow = ({
  item,
  appIndex,
  isLoading,
}: {
  item: Apps[number];
  appIndex: number;
  isLoading?: boolean;
}) => {
  const { open: openDefiApps } = useDefiAppsModalActions(defiAppsId);
  const handleOpenDefiApps = (
    dataItem: AppsDefiDataItemType | undefined,
    defiAppsRowId: string
  ) => {
    openDefiApps({ dataItem, defiAppsRowId });
  };
  const loaderColor = useTextHexColor();

  const {
    isOpen,
    props: { defiAppsRowId },
  } = useDefiAppsStateModal();
  const activeRowStyle = {
    transform: 'scale(1.02)',
    outlineStyle: 'solid',
    outlineColor: 'primary.400',
  };
  const nodeRef = useRef<HTMLDivElement | null>();
  const isVisible = useIsVisible(nodeRef);

  const isAppVisible = isVisible || appIndex < ALWAYS_SHOW_APPS_AMOUNT;
  return (
    <Tr
      ref={(el) => {
        nodeRef.current = el;
      }}
      height="56px"
      key={item.raw.id}
      boxSizing="border-box"
      cursor="pointer"
      outline="2px solid"
      outlineColor="transparent"
      transition="transform 0.5s ease"
      outlineOffset="-6px"
      rounded="12px"
      _hover={activeRowStyle}
      sx={item.raw.id === defiAppsRowId && isOpen ? activeRowStyle : {}}
      onClick={() => handleOpenDefiApps(item, item.raw.id)}
    >
      {isAppVisible
        ? generateTradingTokenComponents({ view: 'table' }).map(
            ({ key, Cell }, i) => {
              const renderAppIndex = () => {
                if (i !== 0) return null;
                const minWidth = '32px';
                if (isLoading)
                  return (
                    <PulseLoader
                      size={6}
                      color={loaderColor}
                      style={{
                        minWidth,
                        paddingLeft: ROW_PADDING_LEFT,
                        opacity: 0.5,
                      }}
                    />
                  );
                return (
                  <Text
                    as="span"
                    fontWeight="semibold"
                    color="gray.400"
                    pl={ROW_PADDING_LEFT}
                    minWidth={minWidth}
                  >{`#${appIndex + 1}`}</Text>
                );
              };
              return (
                <td key={`${item.raw.id}_${key}`} style={{ padding: '2px 0' }}>
                  <HStack py="6px" px="6px">
                    {renderAppIndex()}
                    <CellWrapper isLoading={isLoading}>
                      <Cell app={item} />
                    </CellWrapper>
                  </HStack>
                </td>
              );
            }
          )
        : null}
    </Tr>
  );
};

const SEARCH_URL_PARAM = 'search=';
export const TradingTableList: FC<ITradingTableList> = ({
  apps,
  isLoading,
  orderDir,
  orderBy,
  onOrderChange,
  isSecurityPassed,
  setIsSecurityPassed,
  fetchNext,
  hasMore,
  type,
}) => {
  const t = useTranslations();
  const backgroundColor = useBackgroundHexColor();
  const searchParams = useSearchParams();

  const searchBy = searchParams.get('search');

  const prevApps = usePrevious(apps);
  const searchURLParam = searchBy
    ? `?${SEARCH_URL_PARAM}${searchBy || ''}`
    : `?`;

  const setUrlSearchParam = (value?: string) => {
    window.history.replaceState({ search: searchBy }, '', value);
  };

  const setUrlSearchParamRef = useRef(setUrlSearchParam);
  useEffect(() => {
    if (type !== RouteTypes.my_tokens) return;
    if (!searchBy) return;

    setUrlSearchParamRef.current(`?`);
  }, [type, searchBy]);
  const items = [
    {
      name: t('top-pairs'),
      path: RouteTypes.top_pairs,
      params: searchURLParam,
    },
    {
      name: t('hot-pairs'),
      path: RouteTypes.hot_pairs,
      params: searchURLParam,
    },
    {
      name: t('new-pairs'),
      path: RouteTypes.new_pairs,
      params: searchURLParam,
    },
    // {
    //   name: t('sniper'),
    //   path: '#',
    //   label: 'coming-soon',
    //   params: searchURLParam,
    // },
    {
      name: t('my-tokens'),
      path: RouteTypes.my_tokens,
      params: searchURLParam,
    },
  ];
  const onOrderChangeRef = useRef(onOrderChange);
  const backgroundColorHeader = useColorModeValue('#ccc', '#1C2024');
  const shadowColor = useColorModeValue('#CBD5E0', '#000');

  const { isConnected } = useAccount();
  const { isSignedOut, isSigningIdle } = useUser();

  const [isFirstGuarding, setIsFirstGuarding] = useSessionStorage(
    'isFirstGuarding',
    true
  );
  const [isGuardingAnimation, setIsGuardingAnimation] = useState(true);

  const isMyTokens = type === RouteTypes.my_tokens;

  const isGuarded =
    isWithSecurityPassedSwitch(type) && Boolean(isSecurityPassed);
  const now = String(new Date().toISOString());
  const updatedAt = apps?.length === 0 ? now : apps[0]?.raw?.updatedAt || now;

  const UpdatedAtTextComponent = (
    <Text color="gray.400" fontSize="14px">
      *The information is valid as of the last update at {updatedAt}
    </Text>
  );

  useEffect(() => {
    if (isFirstGuarding) return;
    setIsGuardingAnimation(false);
  }, [isFirstGuarding]);

  useEffect(() => {
    if (!isGuarded || !isFirstGuarding) return;

    const timeout = window.setTimeout(() => {
      setIsFirstGuarding(false);
    }, 9500);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [isFirstGuarding, isGuarded, setIsFirstGuarding]);

  const renderTable = useCallback(() => {
    const tableRowList = isLoading ? prevApps : apps;

    const isGuardedAndAnimating = isGuarded && isGuardingAnimation;

    const isGuardedAndEmpty = isGuarded && tableRowList?.length === 0;

    const isNotConnected = !isConnected || isSigningIdle || isSignedOut;
    const isMyTokensAndNotConnected = isMyTokens && isNotConnected;

    if (isGuardedAndAnimating)
      return (
        <HStack mt="18px" justifyContent="center">
          <FirstGuardedTokensAlert />
        </HStack>
      );

    if (isGuardedAndEmpty)
      return (
        <HStack mt="18px" justifyContent="center">
          <GuardedAndEmptyTokensAlert footer={UpdatedAtTextComponent} />
        </HStack>
      );

    if (isMyTokensAndNotConnected)
      return (
        <HStack px="25px" mt="18px" justifyContent="center">
          <ConnectWalletAlertMessage />
        </HStack>
      );

    return (
      <TableContainer px="12px" overflowX="unset" overflowY="unset">
        <Table
          w="full"
          style={{
            borderCollapse: 'separate',
            borderSpacing: '0px 0px',
            padding: '16px 16px',
          }}
          variant="unstyled"
          size="lg"
        >
          <Thead
            className="sticky"
            top="72px"
            zIndex={3}
            backgroundColor={backgroundColorHeader}
            boxShadow="0px 1px 0px #4A5568, 0px -1px 0px #4A5568"
          >
            <Tr>
              {generateTradingTokenComponents({ view: 'table' }).map(
                ({ key, name, Title }, i) => {
                  return (
                    <td
                      key={key}
                      style={{ paddingLeft: i === 0 ? ROW_PADDING_LEFT : 0 }}
                    >
                      <TitleWrapper>
                        <Title
                          name={name}
                          orderBy={orderBy}
                          orderDir={orderDir}
                          onOrderChange={onOrderChangeRef.current}
                        />
                      </TitleWrapper>
                    </td>
                  );
                }
              )}
            </Tr>
          </Thead>
          <Tbody>
            {tableRowList?.map((item, appIndex) => {
              return (
                <TableRow
                  key={item.raw.id}
                  item={item}
                  isLoading={isLoading}
                  appIndex={appIndex}
                />
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apps,
    orderBy,
    orderDir,
    isLoading,
    isGuardingAnimation,
    backgroundColorHeader,
    shadowColor,
    isConnected,
    isSigningIdle,
    isSignedOut,
  ]);

  return (
    <InfiniteScrollEfficient
      dataLength={apps.length}
      hasMore={Boolean(hasMore)}
      next={() => {
        fetchNext?.();
      }}
      style={{
        overflow: 'clip',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minWidth: MIN_TABLE_WIDTH,
      }}
      loader={
        <VStack justify="center" align="center" w="full">
          <Spinner color="primary.600" />
        </VStack>
      }
    >
      <Box
        className="sticky"
        w="full"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection="row"
        top={0}
        py="18px"
        pr="28px"
        pl="26px"
        zIndex="2"
        background={backgroundColor}
      >
        <InputGroup
          display="flex"
          maxWidth={TOOL_WIDTH}
          alignItems="center"
          flex={1}
        >
          <InputLeftElement
            as={Search}
            color={searchBy ? 'red.500' : 'unset'}
            h="full"
            p="9px"
          />
          <Input
            value={searchBy || ''}
            rounded="18px"
            borderWidth="1px"
            borderColor="black"
            _focus={{
              borderColor: 'blue.400',
            }}
            _dark={{
              borderColor: 'white',
              _focus: { borderColor: 'blue.400' },
            }}
            onChange={(event) => {
              setUrlSearchParam(
                `?${SEARCH_URL_PARAM}${event.target.value || ''}`
              );
            }}
            placeholder={`${t('token-0')}, ${t('symbol-0')}, ${t('address-0')}`}
            isDisabled={type === RouteTypes.my_tokens}
          />
          {Boolean(searchBy) && (
            <InputRightElement
              as={X}
              h="full"
              p="9px"
              cursor="pointer"
              onClick={() => setUrlSearchParam(`?`)}
            />
          )}
        </InputGroup>
        <Stack
          display="flex"
          flex={1}
          alignItems="center"
          justifyItems="center"
        >
          <CustomSwitch items={items} flexShrink={1} />
        </Stack>
        <HStack
          display="flex"
          flex={1}
          justifyContent="flex-end"
          maxWidth={TOOL_WIDTH}
        />
      </Box>
      <Collapse in={isWithSecurityPassedSwitch(type)}>
        <HStack justifyContent="center">
          <Text
            as="span"
            textTransform="capitalize"
            color={isSecurityPassed ? 'gray.800' : 'gray.500'}
            _dark={{
              color: isSecurityPassed ? 'white' : 'gray.300',
            }}
            fontWeight="semibold"
            fontSize="18px"
          >
            {t('filter-suspicious-tokens')}
          </Text>
          <CopyButton
            value={getFilterSuspiciousTokensDesc({ t })}
            CustomIcon={InfoCircle}
            size="20px"
            color={isSecurityPassed ? 'gray.800' : 'gray.500'}
            _dark={{
              color: isSecurityPassed ? 'white' : 'gray.300',
            }}
          />
          <Switch
            onChange={() => {
              setIsSecurityPassed((isPassed) => !isPassed);
            }}
            isChecked={isSecurityPassed}
            size="md"
            sx={{
              '.chakra-switch__track::after': {
                position: 'absolute',
                content: isSecurityPassed ? '"ON"' : '"OFF"',
                left: isSecurityPassed ? '30%' : '68%',
                top: '13px',
                color: 'white',
                display: 'block',
                transform: 'translate(-50%,-50%)',
                fontWeight: '700',
                fontSize: '14px',
              },
              '.chakra-switch__track': {
                width: '50px',
                height: '20px',
              },
              '.chakra-switch__thumb': {
                marginTop: '2px',
                _checked: {
                  transform: 'translateX(34px)',
                },
              },
            }}
          />
        </HStack>
      </Collapse>
      <Box>{renderTable()}</Box>
    </InfiniteScrollEfficient>
  );
};

'use client';

import { useTranslations } from 'next-intl';
import type { FC, ReactNode } from 'react';
import { GuardedTokensAlert } from './GuardedTokensAlert';
import { TextsUpAnimation } from './TextsUpAnimation';

interface IFirstGuardedTokensAlert {
  footer?: ReactNode;
}

export const FirstGuardedTokensAlert: FC<IFirstGuardedTokensAlert> = ({
  footer,
}) => {
  const t = useTranslations();
  return (
    <GuardedTokensAlert
      header={t('guarded-by-intentable')}
      canClose={false}
      body={
        <TextsUpAnimation
          texts={[
            t('scanning-for-low-liquidity-tokens'),
            t('identifying-potential-scams'),
            t('detecting-honey-pot-schemes'),
            t('matching-similar-scam-contracts'),
            t('flagging-closed-or-unique-contracts'),
          ]}
        />
      }
      footer={footer}
    />
  );
};

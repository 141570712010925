'use client';

import { Text } from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import type { FC, ReactNode } from 'react';
import { GuardedTokensAlert } from './GuardedTokensAlert';

interface IGuardedAndEmptyTokensAlert {
  footer?: ReactNode;
}

export const GuardedAndEmptyTokensAlert: FC<IGuardedAndEmptyTokensAlert> = ({
  footer,
}) => {
  const t = useTranslations();
  return (
    <GuardedTokensAlert
      header={t('guarded-by-intentable')}
      body={
        <>
          <Text fontWeight="400" fontSize="16px">
            {t(
              'our-algorithm-protects-you-by-showing-only-safe-and-verified-tokens'
            )}
          </Text>
          <Text fontWeight="400" fontSize="16px">
            {t(
              'currently-no-tokens-have-passed-our-checks-so-all-visible-tokens-are-flagged-as-potential-scams'
            )}
          </Text>
          <Text fontWeight="400" fontSize="16px">
            {t('we-will-add-legitimate-tokens-as-they-are-verified')}
          </Text>
        </>
      }
      footer={footer}
    />
  );
};
